var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{staticClass:"noprint",attrs:{"color":"white","fixed":"","height":"90"}},[_c('v-app-bar-nav-icon',{staticClass:"hidden-md-and-up",staticStyle:{"color":"black"},attrs:{"large":""},on:{"click":_vm.toggleDrawer}}),_c('div',{staticStyle:{"width":"15px"}}),_c('img',{staticClass:"hover",staticStyle:{"margin-left":"-5px","margin-right":"8px"},attrs:{"src":require('@/assets/img/spac-purple-default.png'),"alt":"ILLINOIS SENTENCING POLICY ADVISORY COUNCIL","width":_vm.logoWidth()},on:{"click":function($event){_vm.$router.push('/').catch(err => {
        _vm.$vuetify.goTo(0);
      })}}}),_vm._v("     "),_c('v-toolbar-title',{staticClass:"heavy hover",on:{"click":function($event){_vm.$router.push('/').catch(err => {
        _vm.$vuetify.goTo(0);
      })}}},[_c('span',{staticClass:"agency default-font",staticStyle:{"color":"purple"}},[_vm._v("ILLINOIS SENTENCING POLICY ADVISORY COUNCIL")])]),_c('div',{staticClass:"flex-grow-1"}),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down"},[_vm._l((_vm.sections),function(link){return _c('span',{key:link.title,staticClass:"flexitem"},[(
          link.displayNav &&
            link.slug !== 'publications' &&
            link.slug !== 'meetings'
        )?_c('span',[_c('v-menu',{staticStyle:{"background":"yellow"},attrs:{"offset-y":"","left":"","eager":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(link.hasSubMenus && link.pages.length > 0)?_c('v-btn',_vm._g({staticClass:"heavy white default-font",staticStyle:{"height":"99%","margin-bottom":"1px","margin-top":"0px","font-size":"13px","font-family":"'Lato', sans-serif !important"},attrs:{"depressed":""}},on),[_vm._v(" "+_vm._s(link.title)),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v("arrow_drop_down")])],1):_c('v-btn',{staticClass:"heavy white default-font",staticStyle:{"height":"99%","margin-bottom":"1px","margin-top":"0px","font-size":"13px","font-family":"'Lato', sans-serif !important"},attrs:{"depressed":"","to":`/${link.slug}`}},[_vm._v(" "+_vm._s(link.title)+" ")])]}}],null,true)},[_c('v-list',{attrs:{"nav":"","dense":"","flat":"","elevation":"1"}},[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((link.pages),function(subItem,i){return _c('div',{key:i},[(subItem.displayNav && subItem.isPublished)?_c('v-list-item',[_c('v-list-item-content',{on:{"click":function($event){_vm.$router
                        .push(`/${link.slug}/${subItem.slug}`)
                        .catch(err => {
                          _vm.$vuetify.goTo(0);
                        })}}},[_c('v-list-item-title',{staticStyle:{"font-size":"14px","font-weight":"bold"}},[(subItem.addDivider)?_c('span',[_c('div',{staticClass:"mb-5"},[_c('v-divider')],1)]):_vm._e(),_vm._v(" "+_vm._s(subItem.title)+" ")])],1)],1):_vm._e()],1)}),0)],1)],1)],1):_vm._e(),(link.displayNav && link.slug === 'publications')?_c('span',[_c('NavPublications')],1):_vm._e(),(link.displayNav && link.slug === 'meetings')?_c('span',[_c('NavMeetings')],1):_vm._e()])}),_c('v-btn',{attrs:{"depressed":"","small":"","color":"white","to":"/search","aria-label":"Search SPAC"}},[_c('v-icon',{staticStyle:{"color":"purple"}},[_vm._v("search")])],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{staticClass:"pa-0",attrs:{"color":"secondary","app":"","dark":"","clipped":"","disable-resize-watcher":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-list',{staticClass:"drawer",attrs:{"rounded":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-center heavy"},[_c('img',{staticClass:"hover",attrs:{"src":require('@/assets/img/spac-horizontal-white.png'),"alt":"THE ILLINOIS SENTENCING POLICY ADVISORY COUNCIL","width":"220"},on:{"click":function($event){_vm.$router.push('/').catch(err => {
                      _vm.$vuetify.goTo(0);
                    })}}})])],1)],1)],1)],1)],1)],1),_c('v-divider'),_c('v-container',[_c('v-row',{staticClass:"fill-height"},[_c('v-col',_vm._l((_vm.sections),function(link){return _c('v-list',{key:link.name,attrs:{"dense":""}},[_c('v-list-item',{staticClass:"link-item"},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"push-right",staticStyle:{"font-weight":"900 !important","cursor":"pointer"},on:{"click":function($event){_vm.$router
                    .push(link.slug === 'home' ? '/' : `/${link.slug}`)
                    .catch(err => {
                      _vm.$vuetify.goTo(0);
                    })}}},[_vm._v(_vm._s(link.title))])],1)],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
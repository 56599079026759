var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"mx-auto py-0 mb-8",class:{
      'elevation-3': _vm.elevation,
      'elevation-0': !_vm.elevation
    },staticStyle:{"border-bottom":"1px solid #ddd"}},[_c('v-row',[(_vm.displayNewsLink)?_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-right spac-purple mr-10"},[_c('div',{staticClass:"heavy category hover",staticStyle:{"color":"#fff !important","text-decoration":"none !important","font-weight":"900 !important"},on:{"click":function($event){_vm.$router.push('/news').catch(err => {
                _vm.$vuetify.goTo(0);
              })}}},[_vm._v(" SPAC News ")])])]):_vm._e()],1),_c('v-list-item',{attrs:{"three-line":""}},[_c('v-list-item-content',[_c('PostedDate',{staticStyle:{"margin-left":"-15px"},attrs:{"createdAt":_vm.content.createdAt,"updatedAt":_vm.content.updatedAt}}),_c('v-list-item-title',{staticClass:"headline mb-1",staticStyle:{"font-weight":"700","font-size":"28px !important","margin":"0","padding":"0"}},[_c('router-link',{staticClass:"news-link",attrs:{"to":`/news/${_vm.content.slug}`}},[_vm._v(_vm._s(_vm.content.title))])],1)],1)],1),_c('v-card-text',{staticStyle:{"font-size":"14px"}},[(_vm.readMore)?_c('readmore',{staticClass:"markdown-body",attrs:{"content":_vm.renderToHtml(_vm.content.content),"tags":_vm.content.tags,"height":_vm.height,"readMoreText":"Read More","hideReadLess":true,"fullHeight":_vm.fullHeight,"eventLabel":_vm.content.title}}):_c('div',{staticClass:"dynamic-content lato",domProps:{"innerHTML":_vm._s(_vm.renderToHtml(_vm.content.content))},on:{"click":_vm.handleClicks}}),(!_vm.readMore)?_c('TagList',{staticClass:"mt-10",attrs:{"tags":_vm.content.tags}}):_vm._e(),_c('v-container',{staticClass:"mt-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(
                _vm.content.updatedAt &&
                  _vm.displayUpdated(_vm.content.createdAt, _vm.content.updatedAt)
              )?_c('div',{staticClass:"text-right"},[_vm._v(" Last updated: "+_vm._s(_vm._f("timeAgoFormat")(_vm.content.updatedAt))+" ")]):_vm._e()])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-img',{staticClass:"mt-0",staticStyle:{"border-bottom":"1px solid #bbb"},attrs:{"src":require("@/assets/img/spac-columns.jpg"),"lazy-src":require("@/assets/img/spac-columns-min.jpg"),"height":"500","aspect-ratio":"1.3"},on:{"click":_vm.hideQuery}},[_c('div',{staticClass:"banner"},[_c('v-container',{staticClass:"text-center",attrs:{"bg":"","grid-list-md":"","text-center":""}},[_c('v-layout',{attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',{staticClass:"text-center"},[_c('div',{class:{
                'splash-margin-sm':
                  _vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm,
                'splash-margin-xl':
                  _vm.$vuetify.breakpoint.md ||
                  _vm.$vuetify.breakpoint.lg ||
                  _vm.$vuetify.breakpoint.xl
              }},[_c('h1',{staticClass:"default-font home-title",staticStyle:{"color":"#fff","font-weight":"900"}},[_vm._v(" ILLINOIS SENTENCING POLICY ADVISORY COUNCIL ")]),_c('blockquote',{staticClass:"splash"},[_vm._v(" All penalities "),_c('span',{staticStyle:{"color":"#fff","font-weight":"900"}},[_vm._v("shall be determined")]),_vm._v(" both according to the seriousness of the offense and with the object of "),_c('span',{staticStyle:{"color":"#fff","font-weight":"900"}},[_vm._v("restoring the offender")]),_vm._v(" to useful citizenship. "),_c('cite',[_vm._v("The Illinois Constitution Article I, Section 11 (1970)")])]),_c('v-flex',{staticClass:"text-center",staticStyle:{"width":"100% !important"}},[_c('SearchMini',{staticClass:"py-3 px-3 mt-5",staticStyle:{"background":"#fff","position":"relative"}})],1)],1)])],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
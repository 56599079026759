var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"pt-3 pb-4 elevation-2",class:{ cardBackground: _vm.mode !== 'minimal', minHeight: _vm.imageExists },staticStyle:{"border-bottom":"1px solid #eee"}},[_c('div',{staticClass:"px-4 lato"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-right spac-purple"},[_c('div',{staticClass:"heavy category",on:{"click":function($event){return _vm.routeToCategory(_vm.item)}}},[_vm._v(" "+_vm._s(_vm.strapiEnumToObject("publications", _vm.item.category)[0].title)+" ")])])])],1),_c('div',{staticClass:"mt-5",staticStyle:{"font-weight":"700","font-size":"12px","color":"purple"}},[_vm._v(" "+_vm._s(_vm.item.year)+" ")]),_c('h2',{staticClass:"mb-3",staticStyle:{"margin":"0","padding":"0","width":"100%"}},[(_vm.item.mediaMaterial && _vm.item.mediaMaterial.file)?_c('span',{staticClass:"hover",staticStyle:{"font-family":"'Roboto',sans-serif !important"},on:{"click":function($event){return _vm.download(_vm.item)}}},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]):(
            _vm.item.externalMediaMaterial && _vm.item.externalMediaMaterial.url
          )?_c('span',{staticClass:"hover",on:{"click":function($event){return _vm.gotoExternal(_vm.item)}}},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.item.title)+" ")])]),_c('v-card-text',{staticClass:"pb-5"},[_c('div',{staticClass:"hover",staticStyle:{"float":"left","margin-bottom":"30px"}},[(
              _vm.item.mediaMaterial &&
                _vm.item.mediaMaterial.thumbnail &&
                !_vm.$browserDetect.isIE
            )?_c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-img',{staticClass:"cover elevation-1 px-1",staticStyle:{"margin-right":"20px","border":"1px solid #bbb"},attrs:{"contain":true,"src":_vm.getThumbnailLink(_vm.item.mediaMaterial),"lazy-src":require('@/assets/img/spac-purple-lazy.png'),"width":"200"},on:{"click":function($event){return _vm.download(_vm.item)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey darken-5"}})],1)]},proxy:true}],null,true)},[_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#B158C2"}},[_c('v-btn',[_c('v-icon',[_vm._v("cloud_download")])],1)],1):_vm._e()],1)],1)]}}],null,false,1962596094)})],1):(
              _vm.item.externalMediaMaterial &&
                _vm.item.externalMediaMaterial.thumbnail &&
                !_vm.$browserDetect.isIE
            )?_c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-img',{staticClass:"cover elevation-0 px-1",staticStyle:{"margin-right":"20px","border":"1px solid #bbb"},attrs:{"contain":true,"src":_vm.getThumbnailLink(_vm.item.externalMediaMaterial),"lazy-src":require('@/assets/img/spac-purple-lazy.png'),"width":"200"},on:{"click":function($event){return _vm.gotoExternal(_vm.item)}}},[_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"#036358"}},[_c('v-btn',[_c('v-icon',[_vm._v("open_in_new")])],1)],1):_vm._e()],1)],1)]}}])})],1):_vm._e()]),_c('div',{staticClass:"default-font table-font",class:{ summaryMinHeight: _vm.imageExists }},[_vm._v(" "+_vm._s(_vm.item.summary)+" "),_c('div',{class:{ 'text-right': _vm.mode === 'minimal' }},[(_vm.item.mediaMaterial && _vm.item.mediaMaterial.file)?_c('v-chip',{staticClass:"mt-4 hover",class:{ pullLeft: !_vm.item.summary },attrs:{"small":"","color":"primary","outlined":"","pill":""},on:{"click":function($event){return _vm.download(_vm.item)}}},[_vm._v(" READ "),_c('v-icon',{attrs:{"right":""}},[_vm._v("cloud_download")])],1):_vm._e(),(
                _vm.item.externalMediaMaterial && _vm.item.externalMediaMaterial.url
              )?_c('v-chip',{staticClass:"mt-4 hover",attrs:{"small":"","color":"primary","outlined":"","pill":""},on:{"click":function($event){return _vm.gotoExternal(_vm.item)}}},[_vm._v(" GO TO "),_c('v-icon',{attrs:{"right":""}},[_vm._v("open_in_new")])],1):_vm._e()],1)])]),(_vm.mode !== 'minimal')?_c('v-card-text',{staticClass:"mt-3"},[_c('TagList',{staticStyle:{"margin-left":"-20px"},attrs:{"tags":_vm.item.tags}})],1):_c('v-card-text',{staticClass:"mt-3"},[_c('div',{staticStyle:{"height":"10px"}})])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }